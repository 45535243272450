import { AuthenticatedContent, AuthorizedContent, MfaSecuredContent } from '@ten-netzkundenportal/ui-auth-utils';
import { CenteredLoadingSpinner } from '@ten-netzkundenportal/ui-components';
import '@ten-netzkundenportal/ui-components/dist/base.css';
import * as React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { EnsureUnauthenticated } from './EnsureUnauthenticated';
import { AccountManagement } from './account-management/AccountManagement';
import { ConfirmEmailChange } from './account-management/confirm-email-change/ConfirmEmailChange';
import { MfaManagement } from './account-management/mfa-management/MfaManagement';
import { MfaSetup } from './account-management/mfa-setup/MfaSetup';
import appConfig from './app.config';
import PasswordForgotContainer from './password-forgot';
import PasswordRecoveryContainer from './password-recovery';
import { ACCOUNT_MANAGEMENT, ACCOUNT_PATH, MFA_MANAGEMENT, MFA_SETUP, REGISTRATION_PATH } from './routes';
import SignUpFormContainer from './signup';
import SuccessContainer from './signup/success/EmailRegistrationSuccessContainer';
import { VerificationContainer } from './verification/VerificationContainer';

export const Root = (): React.ReactElement => (
    <Router>
        <Switch>
            <Route path={REGISTRATION_PATH}>
                <Route path={`${REGISTRATION_PATH}/verifizierung`}>
                    <EnsureUnauthenticated>
                        <VerificationContainer />
                    </EnsureUnauthenticated>
                </Route>
                <Route path={`${REGISTRATION_PATH}/erfolg`} component={SuccessContainer} />
                <Route exact path={`${REGISTRATION_PATH}/`} component={SignUpFormContainer} />
            </Route>
            <Route path={`${ACCOUNT_PATH}/passwort-zuruecksetzen`}>
                <EnsureUnauthenticated>
                    <PasswordRecoveryContainer />
                </EnsureUnauthenticated>
            </Route>
            <Route path={`${ACCOUNT_PATH}/passwort-vergessen`}>
                <EnsureUnauthenticated>
                    <PasswordForgotContainer />
                </EnsureUnauthenticated>
            </Route>
            <Route path={`${ACCOUNT_PATH}/accountverwaltung/e-mail-bestaetigung`} component={ConfirmEmailChange} />
            <Route path={MFA_SETUP}>
                <AuthenticatedContent>
                    <MfaSecuredContent
                        appConfig={appConfig}
                        loadingContent={<CenteredLoadingSpinner />}
                        permitAccessIfMfaIsNotSetup
                        redirectUriOnError={ACCOUNT_MANAGEMENT}
                    >
                        <MfaSetup />
                    </MfaSecuredContent>
                </AuthenticatedContent>
            </Route>
            <Route path={MFA_MANAGEMENT}>
                <AuthenticatedContent>
                    <MfaSecuredContent
                        appConfig={appConfig}
                        loadingContent={<CenteredLoadingSpinner />}
                        redirectUriOnError={ACCOUNT_MANAGEMENT}
                    >
                        <MfaManagement />
                    </MfaSecuredContent>
                </AuthenticatedContent>
            </Route>
            <Route exact path={[ACCOUNT_MANAGEMENT, `${ACCOUNT_PATH}/passwort-aendern`]}>
                <AuthenticatedContent>
                    <AuthorizedContent acceptedCustomerTypes={['company', 'installer', 'private', 'projectpartner']}>
                        <AccountManagement />
                    </AuthorizedContent>
                </AuthenticatedContent>
            </Route>
        </Switch>
    </Router>
);
