import { useMsal } from '@azure/msal-react';
import {
    LoginRedirectState,
    MfaMethod,
    getCurrentUriForRedirect,
    useTokenClaims,
} from '@ten-netzkundenportal/ui-auth-utils';
import {
    ErrorBox,
    Form,
    FormItem,
    Mail,
    RadioIcon,
    TeagPiktoHandy,
    createProcessGuidanceButtons,
} from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { ReactElement, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import appConfig from '../../app.config';
import { ACCOUNT_MANAGEMENT } from '../../routes';
import { mfaMethodDisplayText } from '../../util/mfaMethodDisplayText';

type TwoFactorAuthenticationFormData = {
    mfaMethod: MfaMethod;
};

export const MfaSetup = (): ReactElement => {
    const history = useHistory();
    const { instance } = useMsal();
    const { availableMfaMethod } = useTokenClaims();

    const {
        control,
        handleSubmit,
        register,
        formState: { isValid, isSubmitting },
    } = useForm<TwoFactorAuthenticationFormData>({
        defaultValues: {
            mfaMethod: availableMfaMethod,
        },
        mode: 'onTouched',
    });

    const [showError, setShowError] = useState(false);
    const selectedMfaMethod = useWatch({ control, name: 'mfaMethod' });

    const onSubmit = async () => {
        setShowError(false);

        const loginRedirectState: LoginRedirectState = {
            redirectUri: ACCOUNT_MANAGEMENT,
            redirectUriOnError: getCurrentUriForRedirect(),
        };

        await instance
            .loginRedirect({
                extraQueryParameters: { mfaAction: 'setup', mfaMethodToSetup: selectedMfaMethod },
                scopes: [appConfig.tenantApplicationClientId],
                state: JSON.stringify(loginRedirectState),
            })
            .catch((error) => {
                console.error('Error during MFA setup', error);
                setShowError(true);
            });
    };

    const { nextButton, prevButton } = createProcessGuidanceButtons({
        onNext: handleSubmit(onSubmit),
        onBack: () => history.goBack(),
        isNextValid: selectedMfaMethod !== availableMfaMethod && isValid && !isSubmitting,
        loading: isSubmitting,
    });

    return (
        <Form
            title="Zwei-Faktor-Authentifizierung einrichten"
            leftButton={prevButton}
            rightButton={nextButton}
            handleSubmit={onSubmit}
        >
            <FormItem
                label="Welche Methode der Zwei-Faktor-Authentifizierung möchten Sie einrichten und zukünftig zur Anmeldung nutzen?"
                className="flex flex-col w-full gap-y-8"
            >
                <div className="flex gap-6 md:flex-row w-full items-center justify-center">
                    <div className="max-w-[400px] h-full flex-1">
                        <RadioIcon
                            checked={selectedMfaMethod === 'email'}
                            id="email"
                            value="email"
                            {...register('mfaMethod', { required: true })}
                            title={mfaMethodDisplayText.email}
                            additionalClassName="pt-5"
                            topIcon={
                                <div className="w-[50px] py-4">
                                    <Mail />
                                </div>
                            }
                            bottomIcon={
                                <span className="text-center mx-10 text-black py-4 h-full">
                                    Zur Anmeldung wird ein Prüfcode an Ihre Account-E-Mail-Adresse versendet.
                                </span>
                            }
                        >
                            <b>{mfaMethodDisplayText.email}</b>
                        </RadioIcon>
                    </div>

                    <div className="flex max-w-[400px] h-full flex-1">
                        <RadioIcon
                            checked={selectedMfaMethod === 'totp'}
                            id="totp"
                            value="totp"
                            {...register('mfaMethod', { required: true })}
                            title={mfaMethodDisplayText.totp}
                            additionalClassName="pt-5"
                            topIcon={
                                <div className="w-[50px] py-4">
                                    <TeagPiktoHandy />
                                </div>
                            }
                            bottomIcon={
                                <span className="text-center mx-10 text-black py-4 h-full">
                                    Zur Anmeldung ist eine Authenticator App erforderlich.
                                </span>
                            }
                        >
                            <b>{mfaMethodDisplayText.totp}</b>
                        </RadioIcon>
                    </div>
                </div>
                <ErrorBox initialShowError={showError} />
            </FormItem>
        </Form>
    );
};
