// eslint-disable-next-line prefer-destructuring, @typescript-eslint/dot-notation
const env = window['env'];

const appConfig = {
    tenantApplicationClientId: env.TENANT_APPLICATION_CLIENT_ID,
    tenantName: env.TENANT_NAME,
    tenantDomain: `${env.TENANT_NAME}.onmicrosoft.com`,
    userFlowName: env.AUTH_FLOW_NAME,
    services: {
        accountApi: '/account-api',
    },
    show2FA: env.SHOW_2FA === 'true',
};

export default appConfig;
