import { useMsal } from '@azure/msal-react';
import { Button, ErrorBox } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import { TextButtonRow } from '../../components/TextButtonRow';
import useApi from '../../hooks/useApi';
import { DeleteAccountModal } from './DeleteAccountModal';
import { deleteAccount } from './api/deleteAccount';

export const DeleteAccountContainer = (): ReactElement => {
    const { instance } = useMsal();
    const deleteAccountApi = useApi(deleteAccount);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);

    const confirmDeletion = async () => {
        try {
            await deleteAccountApi();
            await instance.logout();
        } catch (error) {
            console.error('Failed to delete account', error);
            setShowError(true);
            setIsModalOpen(false);
        }
    };

    return (
        <div>
            <div className="flex flex-col gap-y-6 py-10">
                <h3 className="text-medium font-bold">Account löschen</h3>

                <TextButtonRow
                    text={
                        <span>
                            Hier haben Sie die Möglichkeit Ihren Account zu löschen. Bitte beachten Sie, dass Ihre Daten
                            nicht wiederhergestellt werden können und Sie anschließend automatisch abgemeldet werden.
                        </span>
                    }
                    button={
                        <Button
                            type="primary"
                            label="Löschen"
                            onClick={() => {
                                setIsModalOpen(true);
                            }}
                        />
                    }
                />
                {showError && <ErrorBox position="!place-self-end" onClick={() => setShowError(false)} />}
            </div>
            {isModalOpen && (
                <DeleteAccountModal
                    onConfirm={confirmDeletion}
                    onCancel={async () => {
                        setIsModalOpen(false);
                    }}
                />
            )}
        </div>
    );
};
