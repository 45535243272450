import * as React from 'react';

type TextButtonProps = {
    text: React.ReactNode;
    button: React.ReactNode;
};

export const TextButtonRow = ({ text, button }: TextButtonProps) => (
    <div className="flex flex-col sm:flex-row justify-between gap-y-6 sm:gap-x-6">
        <div>{text}</div>
        <div className="flex-shrink-0 ml-auto">{button}</div>
    </div>
);
