import { useTokenClaims } from '@ten-netzkundenportal/ui-auth-utils';
import { CenteredLoadingSpinner, LinkButton } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useEffect, useState } from 'react';

import appConfig from '../app.config';
import { TextButtonRow } from '../components/TextButtonRow';
import useApi from '../hooks/useApi';
import { MFA_MANAGEMENT, MFA_SETUP } from '../routes';
import { formatDate } from '../util/formatDate';
import { mfaMethodDisplayText } from '../util/mfaMethodDisplayText';
import { getAccountManagement } from './api/getAccountManagement';
import { ChangeEmailContainer } from './change-email/ChangeEmailContainer';
import PasswordChangeContainer from './change-password/PasswordChangeContainer';
import { DeleteAccountContainer } from './delete-account/DeleteAccountContainer';

export const AccountManagement = (): React.ReactElement => {
    const getAccountManagementApi = useApi(getAccountManagement);

    const tokenClaims = useTokenClaims();

    const [accountEmail, setAccountEmail] = useState<string>();
    const [hasAssignedProcess, setHasAssignedProcess] = useState<boolean>(true);
    const [accountManagementLoadingError, setAccountManagementLoadingError] = useState<boolean>(false);

    useEffect(() => {
        setAccountEmail(undefined);
        setAccountManagementLoadingError(false);

        getAccountManagementApi()
            .then((response) => {
                setAccountEmail(response.email);
                setHasAssignedProcess(response.hasAssignedProcess);
            })
            .catch((error) => {
                setAccountManagementLoadingError(true);
                console.error('Error during loading account email', error);
            });
    }, [getAccountManagementApi]);

    if (accountManagementLoadingError) {
        return (
            <div className="flex flex-col gap-y-6 py-10">
                <p>
                    Leider ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt
                    erneut.
                </p>
            </div>
        );
    }

    if (!accountEmail) {
        return <CenteredLoadingSpinner />;
    }

    return (
        <>
            <PasswordChangeContainer
                accountEmail={accountEmail}
                customerType={tokenClaims.customerType}
                customerName={tokenClaims.name}
            />
            {appConfig.show2FA && !tokenClaims.availableMfaMethod && (
                <>
                    <div className="bg-dark-grey w-full h-px" />
                    <div className="flex flex-col gap-y-6 py-10">
                        <h3 className="text-medium font-bold">Zwei-Faktor-Authentifizierung</h3>
                        <TextButtonRow
                            text={
                                <span>
                                    Zu Ihrer Sicherheit können Sie eine Zwei-Faktor-Authentifizierung einrichten. Sie
                                    können aus mehreren Methoden Ihre bevorzugte auswählen.
                                </span>
                            }
                            button={<LinkButton label="Einrichten" href={MFA_SETUP} />}
                        />
                    </div>
                </>
            )}
            {appConfig.show2FA && tokenClaims.availableMfaMethod && (
                <>
                    <div className="bg-dark-grey w-full h-px" />
                    <div className="flex flex-col gap-y-6 py-10">
                        <h3 className="text-medium font-bold">Zwei-Faktor-Authentifizierung verwalten</h3>
                        <TextButtonRow
                            text={`Die Zwei-Faktor-Authentifizierung mittels ${mfaMethodDisplayText[tokenClaims.availableMfaMethod]} wurde am ${formatDate(tokenClaims.mfaActivationDate)} aktiviert.`}
                            button={<LinkButton label="Bearbeiten" href={MFA_MANAGEMENT} />}
                        />
                    </div>
                </>
            )}
            <div className="bg-dark-grey w-full h-px" />
            <ChangeEmailContainer accountEmail={accountEmail} />
            {!hasAssignedProcess && (
                <>
                    <div className="bg-dark-grey w-full h-px" />
                    <DeleteAccountContainer />
                </>
            )}
        </>
    );
};
