import { useTokenClaims } from '@ten-netzkundenportal/ui-auth-utils';
import { Button, LinkButton } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

import { TextButtonRow } from '../../components/TextButtonRow';
import { MFA_SETUP } from '../../routes';
import { mfaMethodDisplayText } from '../../util/mfaMethodDisplayText';

export const MfaManagement = (): ReactElement => {
    const history = useHistory();
    const { availableMfaMethod } = useTokenClaims();

    return (
        <div className="w-full flex flex-col gap-y-12 py-10">
            <h3 className="font-bold text-medium">Zwei-Faktor-Authentifizierung verwalten</h3>

            <TextButtonRow
                text={
                    <span>
                        Die Zwei-Faktor-Authentifizierung mittels {mfaMethodDisplayText[availableMfaMethod]} ist
                        aktiviert. Möchten Sie Ihre Zwei-Faktor-Authentifizierung auf eine andere Methode ändern?
                    </span>
                }
                button={<LinkButton label="Ändern" href={MFA_SETUP} />}
            />

            <Button
                label="Zurück"
                type="secondary"
                id="goBack"
                additionalClassName="mt-8"
                onClick={() => history.goBack()}
            />
        </div>
    );
};
